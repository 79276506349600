<script setup lang="ts">
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import router from '/~/router'

const props = defineProps<{
  onCancel?: () => void
  onConfirm?: () => void
}>()

function hide() {
  modal.hide('checkout-preview')
}

function cancel() {
  props.onCancel?.()
  hide()
}

function showActivity() {
  hide()
  router.push({ name: 'activity' })
}

function confirm() {
  hide()
  props.onConfirm?.()
}
</script>

<template>
  <base-mdl
    width="sm"
    title="You have a recent payment that looks similar"
    :padding="false"
    :closable="false"
  >
    <div class="space-y-2.5 px-5 pb-2.5 sm:px-[30px]">
      <p>
        This appears to be a duplicate transaction. We have recently processed a
        payment to the same payee and with the same amount in less than 24
        hours. Do you want to proceed with the transaction?
      </p>
      <p>
        You can view the previous transaction
        <span
          class="cursor-pointer font-bold text-red-700 underline hover:text-red-900"
          @click="showActivity"
        >
          here.
        </span>
      </p>
    </div>
    <div class="m-5 flex items-center justify-center space-x-5">
      <base-button
        class="flex-1 sm:flex-none"
        look="outlined-color"
        @click="cancel"
      >
        Cancel
      </base-button>
      <base-button class="flex-1 sm:flex-none" @click="confirm">
        Continue
      </base-button>
    </div>
  </base-mdl>
</template>
